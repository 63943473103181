import { Box } from '@mui/material';
import { CellClassParams, ColDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import { isAxiosError } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';

import { CPGHeader, TPreparedAct, UpdatedWorkPosition, Work, WorkPosition, monthlyChart } from '@/api/ksg/ksg.def';

import s from '@/components/AddLevelKsg/AddLevelKsg.module.scss';

import type { TAgGridContext } from '@/pages/KSGTable';

import { monthsList } from '@/shared/constants/date';
import { IGetTargetRequestFuncArgs, IKSGSubmitParams } from '@/shared/def';
import { isShiftErrorsData } from '@/shared/guards/error.guards';
import { onSubmitRejected, onSubmitSuccess } from '@/shared/utils';
import {
    isOperationalDatesChangesBlockedByFact,
    isOperationalDatesChangesBlockedByZeroFact,
} from '@/shared/utils/error.utils';

import { clearWorks, onEditAgKsg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { IMonthPlanFilter, handleChangeMonthPlanfilter, handleResetMonthPlanfilter } from '@/store/slices/filtersSlice';
import { store } from '@/store/store';

import { req } from '../WorkManagment/api/api';
import { OFFER_COL_DEFS } from './AgGrid.config';
import { ColumnFilter } from './components/ColumnFilter/ColumnFilter';
import { IColumnFilterProps } from './components/ColumnFilter/ColumnFilter.def';

export const getOfferColDefs = (key: 'lsrCiphers' | 'reportingPeriodInfo', isOfferActive?: boolean) => {
    if (!isOfferActive) return [];
    return OFFER_COL_DEFS[key];
};

export const transformWorkPositionToWork = (work: Work, workPosition: WorkPosition): UpdatedWorkPosition => {
    return {
        ...workPosition,
        workName: workPosition.name,
        isWorkPosition: true,
        volumeTotal: workPosition.quantity,
        parentId: work.id,
    };
};

/**
 * TODO
 * В дальнейшем здесь будет настраиваться клик по ячейкам ганта
 * после переноса диаграммы Ганта в AgGrid
 */

const COLUMN_FILTER_OPTIONS = [
    { label: 'Оперативный план есть', value: 'exist' },
    { label: 'Оперативного план нет', value: 'not_exist' },
];

const COLUMN_FILTER_PROPS = (month: CPGHeader) =>
    ({
        title: 'Фильтр по опер. плану',
        options: COLUMN_FILTER_OPTIONS,
        IconButtonProps: { disableRipple: true },
        defaultValue:
            store.getState().filters.filters.planFilter.filter((planFilter: IMonthPlanFilter) => {
                return planFilter.date.first === month.year && planFilter.date.second === month.month;
            })[0]?.status || null,
        onChange: (value: string) => {
            if (value) {
                store.dispatch(clearWorks());
                store.dispatch(
                    handleChangeMonthPlanfilter({
                        date: {
                            first: month.year,
                            second: month.month,
                        },
                        status: value as IMonthPlanFilter['status'],
                    })
                );
            }
        },
        onReset: (value: string) => {
            store.dispatch(clearWorks());
            store.dispatch(
                handleResetMonthPlanfilter({
                    first: month.year,
                    second: month.month,
                })
            );
        },
    } as IColumnFilterProps);

const getHeaderMonthCellClass = (doesThisMonthCurrent: boolean) => (params: CellClassParams) => {
    const level = params?.data?.level as keyof typeof classesByLevel;

    const classesByLevel = {
        0: 'current_month_level_zero',
        1: 'current_month_level_one',
        2: 'current_month_level_two',
        3: 'current_month_level_three',
        4: 'current_month_level_four',
        16: 'current_month_level_sixteen',
    };

    if (!params.data.hasChildren) return 'ag-cell-padding-none ag-cell-flex';

    const classByLevel = classesByLevel[level] ?? classesByLevel[4];

    return `ag-cell-padding-none ag-cell-flex ${doesThisMonthCurrent ? classByLevel : ''}`;
};

// TODO:
// 1. Очистить от старого ганта
export const getHeadersMonths = (
    monthsArr: CPGHeader[] | null,
    callback: (
        data: ICellRendererParams<Work, any, TAgGridContext>,
        index: number,
        doesThisMonthCurrent?: boolean
    ) => React.ReactNode,
    columnWidths?: Map<string, any>
) => {
    const overflow = { cellStyle: { overflow: 'hidden' } };
    return (
        monthsArr?.reduce((acc: any[], month, idx) => {
            const header = acc.find((f) => f.headerName === month.year);

            const isCurrentMonth = new Date().getMonth() === month.month - 1;
            const isCurrentYear = month.year === new Date().getFullYear();
            const doesThisMonthCurrent = isCurrentMonth && isCurrentYear;
            const key = idx === 0 ? 'monthlyCharts' : `monthlyCharts_${idx}`;

            if (header) {
                header.children.push({
                    field: 'monthlyCharts',
                    width: columnWidths?.get(key) || 90,
                    suppressMovable: true,
                    ...overflow,
                    headerName: monthsList[month.month - 1],
                    headerClass: doesThisMonthCurrent && 'ag-cell-header-current-month',
                    headerComponent: (data: IHeaderParams<Work, any>) => {
                        return (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                    width: '100%',
                                    gap: '4px',
                                }}
                            >
                                <p
                                    style={{
                                        flex: 1,
                                        textAlign: 'center',
                                    }}
                                >
                                    {data.displayName}
                                </p>
                                <ColumnFilter
                                    filterIconSx={{ fontSize: 12 }}
                                    {...COLUMN_FILTER_PROPS(month)}
                                />
                            </Box>
                        );
                    },
                    cellRenderer: (data: ICellRendererParams<Work, any, any>) =>
                        callback(data, idx, doesThisMonthCurrent),
                    cellClass: getHeaderMonthCellClass(doesThisMonthCurrent),
                } as ColDef);
            } else {
                acc.push({
                    headerName: month.year,
                    suppressMovable: true,
                    ...overflow,
                    children: [
                        {
                            field: 'monthlyCharts',
                            width: columnWidths?.get(key) || 90,
                            headerName: monthsList[month.month - 1],
                            ...overflow,
                            headerClass: doesThisMonthCurrent && 'ag-cell-header-current-month',
                            headerComponent: (data: IHeaderParams<Work, any>) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        textAlign: 'center',
                                        width: '100%',
                                        gap: '4px',
                                    }}
                                >
                                    <p
                                        style={{
                                            flex: 1,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {data.displayName}
                                    </p>
                                    <ColumnFilter
                                        filterIconSx={{ fontSize: 12 }}
                                        {...COLUMN_FILTER_PROPS(month)}
                                    />
                                </Box>
                            ),
                            cellRenderer: (data: ICellRendererParams<Work, any, any>) =>
                                callback(data, idx, doesThisMonthCurrent),
                            cellClass: getHeaderMonthCellClass(doesThisMonthCurrent),
                        } as ColDef,
                    ],
                });
            }
            return acc;
        }, []) || []
    );
};

export function stylesLevelAg(lev: number | null) {
    const stylesByLevel = {
        0: s.level0,
        1: s.level1,
        2: s.level2,
        3: s.level3,
        4: s.level4,
        5: s.level5,
        6: s.level6,
        7: s.level7,
    };
    return stylesByLevel[lev as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7];
}

export const RD_STATUS_OPTIONS = [
    {
        label: 'Согласовано',
        value: 'Согласовано',
    },
    {
        label: 'На согласовании',
        value: 'На согласовании',
    },
    {
        label: 'Корректировка',
        value: 'Корректировка',
    },
    {
        label: 'Разработка',
        value: 'Разработка',
    },
    {
        label: 'В производстве',
        value: 'В производстве',
    },
    {
        label: 'Не в работе',
        value: 'Не в работе',
    },
];

export const VOLUME_TOTAL_CELL_EDITOR_PARAMS = (params: ICellRendererParams<Work>) => {
    let totalMonthlyChartValue = 0;
    params.data?.monthlyCharts.forEach((monthlyChart: monthlyChart) => {
        totalMonthlyChartValue += monthlyChart.plan || 0;
    });
    const maxValue = 999999999;
    return {
        validation: {
            validate: {
                isLessThanTotalMonthlyChartValue: (v: number) => {
                    // console.log(totalMonthlyChartValue)
                    return (
                        v >= totalMonthlyChartValue ||
                        `Общий объем не может быть меньше плана по месяцам. Мин. значение ${totalMonthlyChartValue}`
                    );
                },
                maxValue: (v: number) => v <= maxValue || `Макс. ${maxValue}`,
            },
        },
    };
};

export function getTargetRequestByColDefFieldName({ params, projectID, currentAct }: IGetTargetRequestFuncArgs) {
    switch (params.colDef.field) {
        case 'mainGroup':
            return req.post(`/projects/${projectID}/works/${params.data?.id}/set-main-group`, {
                mainGroup: params.newValue,
            });
        case 'rdStatus':
            return req.patch(`/projects/${projectID}/works/${params.data.id}/update`, {
                [params.colDef.field as string]: params.newValue?.value || null,
            });
        case 'costDone':
            return params.data.hasChildren
                ? req.post(`/projects/${projectID}/works/${params.data.id}/update-cost-done-manually`, {
                      costDoneManually: params.newValue || null,
                  })
                : req.patch(`/projects/${projectID}/works/${params.data.id}/update`, {
                      [params.colDef.field as string]: params.newValue,
                  });
        case 'toClose':
            if (!params.data?.isWorkPosition) {
                return req.patch(`/projects/${projectID}/works/${params.data.id}/set-to-close`, {
                    toClose: params.newValue,
                });
            }

            const preparedAct = params.data.preparedAct as TPreparedAct;

            return req.post(`/projects/works/save-executed-to-close`, {
                actId: currentAct!,
                offerId: preparedAct.offerId,
                volume: params.newValue ?? null,
            });
        default:
            return req.patch(`/projects/${projectID}/works/${params.data.id}/update`, {
                [params.colDef.field as string]: params.newValue,
            });
    }
}

export function submitCellData(args: IKSGSubmitParams) {
    const { params, projectID, dispatch, thenFn, currentAct, openDeleteZeroValueFactDialog } = args;

    const getUpdatedDataByToCloseField = () => {
        const parentRowNode = params.api.getRowNode(params.data.parentId);
        const parentRowData = cloneDeep(parentRowNode?.data) as Work;

        const newWorkPositions = parentRowData?.workPosition!.map((v) => {
            if (v.id === params.data.id) {
                return {
                    ...v,
                    ...params.data,
                };
            }
            return v;
        });
        return { ...parentRowData, workPosition: newWorkPositions };
    };

    const getUpdatedDataByField = (data: any) => {
        if (params.colDef.field === 'toClose' && params.data?.isWorkPosition) return [getUpdatedDataByToCloseField()];
        if (params.colDef.field === 'mainGroup') return [params.data];

        return data.data;
    };

    getTargetRequestByColDefFieldName({
        params: params,
        projectID: projectID as string,
        currentAct: currentAct,
    })
        .then(({ data }) => {
            const updatedData: Work[] = getUpdatedDataByField(data);

            dispatch(onEditAgKsg(updatedData));
            params.api.applyTransaction({ update: data.data as Work[] });
            params.api.redrawRows({ rowNodes: [params.node] });

            onSubmitSuccess(args);
            thenFn && thenFn();
        })
        .catch((error) => {
            console.log('error', error);

            if (!isAxiosError(error)) return;
            const errorData = error.response?.data;
            if (isShiftErrorsData(errorData)) {
                if (isOperationalDatesChangesBlockedByFact(errorData.message)) {
                    params.api.applyTransaction({
                        update: [{ ...params.data, [params.colDef.field as string]: params.oldValue }],
                    });
                    args.enqueueSnackbar(
                        'Изменение оперативной даты невозможно: факт находится за пределами указанного периода.',
                        {
                            variant: 'error',
                        }
                    );
                    return;
                }

                if (isOperationalDatesChangesBlockedByZeroFact(errorData.message)) {
                    params.api.applyTransaction({
                        update: [{ ...params.data, [params.colDef.field as string]: params.oldValue }],
                    });
                    openDeleteZeroValueFactDialog(errorData.zeroFactBlockers);
                    return;
                }
            }

            onSubmitRejected({
                error: error,
                ...args,
            });
        });
}

export const canEditableByPredecessors = (predecessors: string, linkType: 'F' | 'S' = 'F') => {
    if (!predecessors) return true;
    return !predecessors.split(';').some((v) => v.endsWith(linkType));
};
